import React from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';

function Service() {
    function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
        console.log('LiveChatWidget.onNewEvent', event);
    }

    return <LiveChatWidget license="15256332" visibility="maximized" />;
}

export default Service;
