import { faAnglesRight, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Header from '../../components/Layout/components/Header';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function Recharge({ title }) {
    document.title = title;
    let [show, setShow] = useState(true);
    let [select, setSelect] = useState('bank');
    let [money, setMoney] = useState(0);
    let [user, setUser] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    function RechargeHandler() {
        if (!money || money < 220) return toast.warn(`{t('content.recharge.minNap')}`, { theme: 'light' });
        if (select === 'momo') return toast.warn(`{t('content.recharge.baoTriMomo')}`, { theme: 'light' });

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
                { money, select },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;

                if (data.status === 1) toast.success(data.message, { theme: 'light' });
                if (data.status === 2) toast.warn(data.message, { theme: 'light' });
                if (data.status) {
                    setTimeout(() => {
                        // window.location.href = `/recharge/${data.id_txn}`;
                        window.open(
                            '/support',
                            '_blank', // <- This is what makes it open in a new window.
                        );
                    }, 1500);
                } else {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }
    return (
        <div>
            <div className="recharge">
                {/* {show && (
                    <div>
                        <div
                            onClick={() => setShow(false)}
                            className="flex justify-between px-[15px] py-[15px] border-b"
                        >
                            <div className="flex justify-center items-center">
                                <FontAwesomeIcon className="text-[#3498db] text-6xl" icon={faWallet} />
                                <span className="ml-[10px] text-[16px]">Chọn phương thức nạp</span>
                            </div>
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </div>
                        </div>
                        <div className="px-[15px] mt-[15px]">
                            <p className="note text-[12px] text-[#e74c3c]">
                                Lưu ý: Do lượng thông tin nạp quá lớn nên bạn phải kiểm tra kỹ số thẻ tài khoản trước
                                khi nạp. Nền tảng này thay đổi số tài khoản theo thời gian. Nếu bạn có bất kỳ câu hỏi
                                nào, vui lòng nhấp vào dịch vụ khách hàng trực tuyến！
                            </p>
                        </div>
                    </div>
                )} */}

                <div>
                    {/* <p className="my-amount text-[15px] font-semibold text-[#ffa900] bg-[#fff8e9] py-[10px] text-center">
                        Số dư của tôi: {formatMoney(user.money) || '0'}VND
                    </p> */}
                    <div className="text-center my-[25px]">
                        <p className="text-3xl text-[#005652]">{t('content.recharge.seNhanDuoc')}</p>
                        <div className="text-[#ffaa03] text-[32px] font-bold">{formatter.format(money) || '0'}</div>
                        <div className="form-group px-[15px] mt-[25px]">
                            <div className="flex flex-wrap justify-between">
                                {/* <div
                                    onClick={() => setSelect('bank')}
                                    className="w-[48%] p-[10px] flex justify-center rounded-full"
                                    style={{
                                        border: '1px solid #ff3c61',
                                        background: `${select === 'bank' ? '#e74c3c' : ''}`,
                                    }}
                                >
                                    <img
                                        className="w-[24px] h-[24px] mr-[5px]"
                                        src="https://i.imgur.com/joJ0lOB.png"
                                        alt=""
                                    />
                                    <p className="" style={{ color: `${select === 'bank' ? '#fff' : '#005652'}` }}>
                                        BANKING
                                    </p>
                                </div> 
                                <div
                                    onClick={() => setSelect('momo')}
                                    className="w-[48%] p-[10px] flex justify-center rounded-full"
                                    style={{
                                        border: '1px solid #ff3c61',
                                        background: `${select === 'momo' ? '#e74c3c' : ''}`,
                                    }}
                                >
                                    <img
                                        className="w-[24px] h-[24px] mr-[5px]"
                                        src="https://i.imgur.com/Oadl6ec.png"
                                        alt=""
                                    />
                                    <p className="" style={{ color: `${select === 'momo' ? '#fff' : '#005652'}` }}>
                                        MOMO
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                        <div className="form-group px-[15px] mt-[25px]">
                            <p className="text-left ml-[10px] py-[5px] text-[14px] font-semibold">
                                {t('content.recharge.soLuong')}
                            </p>
                            <div className="flex rounded-full p-[10px]" style={{ border: '2px solid #2a313c' }}>
                                <span className="p-[5px] text-[#2a313c] font-semibold text-2xl">USD</span>
                                <input
                                    onChange={(e) => setMoney(e.target.value)}
                                    className="w-[100%] text-right pr-[5px] text-[18px]"
                                    type="tel"
                                    placeholder={t('content.recharge.nhapSoTien')}
                                    spellCheck="false"
                                    maxLength="13"
                                    style={{
                                        backgroundColor: '#d8ebfe',
                                        borderRadius: '5px',
                                        marginLeft: '10px',
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            onClick={() => RechargeHandler()}
                            className="form-group w-[100%] mx-auto px-[15px] mt-[25px]  rounded-full "
                        >
                            <div className="py-[10px] text-[#fff] button-recharge font-semibold rounded-3xl text-3xl">
                                {t('content.recharge.napTienNgay')}
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default Recharge;
