import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function ChinhSachBM() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>{t('content.chinhSachBM.1')}</span>
                <br />
                <br />
                <span>
                    {t('content.chinhSachBM.2')} <br /> {t('content.chinhSachBM.3')}
                </span>
                <br />
                <br />
                <span>
                    {t('content.chinhSachBM.4')} <br /> {t('content.chinhSachBM.5')}
                </span>
                <br />
                <br />
                <span>
                    {t('content.chinhSachBM.6')} <br /> {t('content.chinhSachBM.7')}
                </span>
                <br />
                <br />
                <span>{t('content.chinhSachBM.8')}</span>
                <br />
                <br />
                <span>{t('content.chinhSachBM.9')}</span>
                <br />
                <br />
                <span>{t('content.chinhSachBM.10')}</span>
            </div>
        </div>
    );
}

export default ChinhSachBM;
